import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import TitleImage from "../components/titleImage"
import SEO from "../components/seo"
import './design.less';
import './home.less';
import Navigation from "../components/navigation";

const FilesPage = () => (

<StaticQuery
query={graphql`
    query {
        files: allFile (
          filter: {
            sourceInstanceName : { eq : "files" }
          }) {
          nodes {
            id
            publicURL
            prettySize
            base
            birthTime
          }
        }
        image: file(name: { eq: "title" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid
                }
            }
        }
    }
`}
render={data => (
    <Layout>
    <SEO title="Dokumente" />
    <TitleImage imageData={data.image} />
    <Navigation />
    <div className="page-content home">
        <div>
        <h3>Dokumente</h3>
        <br/>
        <p>
            Hier finden Sie einige Dokumente zum runterladen, wie alte Newsletter und Formulare
        </p>
        <br/>
        <ul class="document-list">
            {data.files.nodes.map((node ) => {
            return (
            <li>
                <a href={node.publicURL} key={node.id} target="_blank" rel="noopener noreferrer">
                    {node.base} ({node.prettySize})
                </a>
            </li>);
            })}
        </ul>
        </div>
    </div>
    </Layout>
    )}
/>
)

export default FilesPage
